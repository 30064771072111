// import Swiper JS
import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

// import Swiper styles
import 'swiper/css';

window.addEventListener('load', ()=> {
    initSlider();
});

window.addEventListener('orientationchange', ()=> {
    initSlider();
})

function initSlider() {
    const wrapperSwiper = document.querySelector('.review-block');
    const swiperElement = wrapperSwiper.querySelector('.swiper');
    const slides = swiperElement.querySelectorAll('.slide-inner');

    const slider = new Swiper(swiperElement, {
        modules: [Navigation, Pagination],
        slidesPerView: 1.15,
        spaceBetween: 15,
        observer: true,
        observeParents: true,
        // centerInsufficientSlides: true,
        // autoHeight: true,
        centeredSlides: true,
        initialSlide: 1,
        breakpoints: {
            650: {
                slidesPerView: 3,
                spaceBetween: 25,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        pagination: {
            el: ".swiper-pagination",
            // clickable: true,
            dynamicBullets: true,
            // dynamicMainBullets: 3,
        },
    });

    slides.forEach((slide, id) => {
        slide.addEventListener('click', ()=>{
            slider.slideTo(id)
        });
    });
}
